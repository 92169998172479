import majorData from "../data/coinGeckoAllCoins.json";
// import Cache from '../Utilities/Cache.js';
export const fetchMultiple = async (flag) => {
  if (flag === "test") {
    console.log("test data");
    return majorData;
  } else {
    const data = await fetch(
      "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false",
      { mode: "cors" }
    ).then((response) => response.json());
    return data;
  }
};
